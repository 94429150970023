/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


.StripeElement {
    width: 100%;
    padding: 0.75rem;
    height: 38px;
    align-items: center;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --tw-border-opacity: 1;

    border: 1px rgba(209, 213, 219, 1) solid;
    border-radius: 0.375rem;
    background-color: #fff;
}

.StripeElement--focus {
    border-color: rgba(99, 102, 241, 1);
}